@charset "UTF-8";

html {
  font-size: 10px;
}
@media (max-width: 1440px) {
  html {
    font-size: 9px;
  }
}

* {
  font-size: 1.6rem;
}

body {
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer */
  -khtml-user-select: none;
  /* KHTML browsers (e.g. Konqueror) */
  -webkit-user-select: none;
  /* Chrome, Safari, and Opera */
  -webkit-touch-callout: none;
  /* Disable Android and iOS callouts*/
  margin: 0;
}

textarea:focus,
input:focus {
  outline: none;
}

button {
  outline: none !important;
}

.grecaptcha-badge {
  z-index: 9999;
  bottom: 5% !important;
}

#leavemessage .react-tel-input .flag-dropdown::before {
  content: "" !important;
}

#leavemessage .react-tel-input .phone-input {
  border-top: 0 !important;
  border-right: 0 !important;
  border-left: 0 !important;
  border-bottom: 0 !important;
  border-radius: 0 !important;
  width: 100% !important;
  margin-bottom: 2rem;
  line-height: 3.2rem;
  border-width: 1px !important;
  background-color: #f2f3f8;
  border-radius: 5px !important;
}

#leavemessage .react-tel-input {
  margin-top: calc(1.6rem + 0.5rem);
}

#leavemessage .react-tel-input .selected-flag {
  height: calc(100% - 2rem);
}

#leavemessage .react-tel-input .phone-input.is-active {
  border-width: 2px !important;
}

#leavemessage .react-tel-input .special-label {
  padding: 0;
  background-color: inherit;
  left: 0;
  font-size: 1.6rem;
  top: -2.6rem;
}

#leavemessage .phone-input:focus {
  outline: none !important;
  box-shadow: none !important;
}
#leavemessage .phone-input.is-active {
  outline: none !important;
  box-shadow: none !important;
}

@media (max-height: 700px) {
  #leavemessage .react-tel-input .phone-input {
    margin-bottom: 1rem;
  }
  #leavemessage .react-tel-input .selected-flag {
    height: calc(100% - 1rem);
  }
  #leavemessage .special-label {
    font-size: 1.4rem;
  }
}

#bookdemo .react-tel-input {
  margin-top: 1.6rem;
}

#bookdemo .react-tel-input .flag-dropdown::before {
  content: "" !important;
}

#bookdemo .react-tel-input .phone-input {
  border-top: 0 !important;
  border-right: 0 !important;
  border-left: 0 !important;
  border-radius: 0 !important;
  border-bottom-color: rgba(0, 0, 0, 0.42) !important;
  width: 100% !important;
  line-height: 3.2rem;
  border-width: 1px !important;
  background-color: inherit;
}

#bookdemo .react-tel-input:hover .phone-input {
  border-width: 2px !important;
  border-bottom-color: rgba(0, 0, 0, 1) !important;
}

#bookdemo .react-tel-input .phone-input.is-active {
  border-bottom-color: #534cdd !important;
  border-width: 2px !important;
}

#bookdemo .react-tel-input .special-label {
  background-color: inherit;
  left: 0;
  top: -12px;
}

#bookdemo .phone-input:focus {
  outline: none !important;
  box-shadow: none !important;
}

#bookdemo .phone-input.is-active {
  outline: none !important;
  box-shadow: none !important;
}
